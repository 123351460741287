<template>
  <v-container>
    <perseu-card title="Categorias">
      <v-btn color="secondary" slot="title-right" @click="create">
        Nova Categoria
      </v-btn>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col cols="12" md="2">
              <v-switch v-model="query.active" label="Ativos"></v-switch>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="query.name"
                label="Nome"
                @keyup.enter.native="list"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar </v-btn>
          </v-row>
        </filters-panel>
        <v-data-table
          :items="categories"
          :headers="headers"
          class="elevation-1"
        >
          <template v-slot:item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
            <v-btn
              small
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon small>check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <v-dialog v-model="dialog" v-if="dialog" width="600px">
      <form-categories
        :value="category"
        @create="addToList"
        @update="updatedForm"
      ></form-categories>
    </v-dialog>
  </v-container>
</template>

<script>
import { find, update } from "@/services/categories-service";

export default {
  components: {
    "form-categories": () => import("@/components/Categories/Form"),
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    categories: [],
    category: {},
    dialog: false,
    query: {
      active: true,
    },
  }),
  created() {
    this.list();
  },
  methods: {
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await find(this.query);
        this.categories = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.category = {};
      this.dialog = true;
    },
    edit(obj) {
      this.category = obj;
      this.dialog = true;
    },
    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await update(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    removeFromList(category) {
      let index = this.categories.indexOf(category);
      this.categories.splice(index, 1);
    },
    addToList(createdCategory) {
      this.categories.push(createdCategory);
      this.dialog = false;
    },
    updatedForm() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
