import api from "@/services/api-service";
import querystring from "querystring";

const url = "/categories";

const find = (query) => {
  return api().get(`${url}?${querystring.stringify(query)}`);
};

const create = (obj) => {
  return api().post(url, obj);
};

const update = (obj) => {
  return api().put(url, obj);
};

const findByName = (name) => {
  return api().get(`${url}/name/${name}`);
};

export { find, create, findByName, update };
